import React from 'react';
import moment from 'moment';

export default function Welcome({
  onJoinGame,
  startAt,
}) {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center gap-10">
      <h1 className="text-8xl text-center font-bold bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text text-transparent">Game Name</h1>
      <div className="flex flex-col items-center gap-2">
        <div className="bg-blue-500 text-2xl text-white px-12 py-4 rounded-full cursor-pointer" onClick={onJoinGame}>開始遊戲</div>
        {startAt && (
          <div className="text-lg">下一場開始時間：{moment(startAt).format('HH:mm')}</div>
        )}
      </div>
    </div>
  );
}
