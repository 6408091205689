import {useRef, useState} from "react";

export default function useStateRef(defaultValue) {
  const [state, _setState] = useState(defaultValue);
  const stateRef = useRef(defaultValue);

  function setState(value) {
    _setState(value);
    stateRef.current = value;
  }

  return [state, stateRef, setState];
}
