import { useMemo } from "react";
import * as Colyseus from 'colyseus.js';

export default function useColyseus() {
  const colyseus = useMemo(() => {
    console.log('connecting to server...');
    return new Colyseus.Client('wss://pacman-server.demacsign.com');
  }, []);

  return colyseus;
}
