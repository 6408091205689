import React, {useEffect} from 'react';
import {useTimer} from '../Hooks';

export default function CountDown({
  onTurnPage,
}) {
  const timer = useTimer(3);

  useEffect(() => {
    if (timer < 0) {
      onTurnPage();
    }
  }, [timer, onTurnPage]);

  return (
    <div className="absolute inset-0 flex flex-col items-stretch justify-center gap-10">
      <h1 className="sr-only text-8xl text-center font-bold">Game Name</h1>
      <div className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-10 flex flex-col items-center gap-2">
        <div className="text-3xl">準備進入遊戲</div>
        <div className="text-lg"><span className="font-mono">{timer}</span> 秒...</div>
      </div>
    </div>
  );
}
