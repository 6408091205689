import React from 'react';

export default function Coupon() {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center gap-10">
      <h1 className="text-6xl text-center font-bold bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text text-transparent">Coupon</h1>
      <p>恭喜你獲得優惠券！</p>
    </div>
  );
}
