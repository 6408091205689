import {useEffect, useRef, useState} from "react";

export default function useTimer(totalSeconds) {
  const [seconds, setSeconds] = useState(totalSeconds + 1);
  const timer = useRef(null);

  function clearTimer() {
    clearInterval(timer.current);
  }

  function reduce() {
    setSeconds(seconds => seconds - 1);
  }

  useEffect(() => {
    seconds < 0 && clearTimer();
  }, [seconds]);

  useEffect(() => {
    reduce();
    timer.current = setInterval(reduce, 1000);

    return clearTimer;
  }, []);

  return seconds;
}
