import React, {useEffect, useState} from 'react';
import {useTimer} from '../Hooks';

import Gift from '../Components/Gift';
import ProgressBar from '../Components/ProgressBar';

export default function AR({
  onDefeat,
  onWin,
}) {

  const limit = 3;
  const [status, setStatus] = useState('playing');
  const timer = useTimer(limit);
  const [gifts, setGifts] = useState((() => {
    const {innerWidth: width, innerHeight: height} = window;
    return Array(3).fill(null).map(gift => ({
      x: Math.floor(Math.random() * (width - 36)),
      y: Math.floor(Math.random() * (height - 36)),
      hidden: false,
    }));
  })());

  function handleTapGift(_gift) {
    setGifts(gifts => gifts.map(gift => {
      if (gift.x === _gift.x && gift.y === _gift.y) {
        return {...gift, hidden: true};
      }

      return gift;
    }));
  }

  useEffect(() => {
    if (timer < 0) {
      setStatus('defeat');
    }
  }, [timer]);

  useEffect(() => {
    if (gifts.every(gift => gift.hidden)) {
      onWin();
    }
  }, [gifts, onWin]);

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center gap-10">
      {status === 'playing' && (
        <>
          <div className="absolute top-5 inset-x-5">
            <ProgressBar percentage={timer * 100 / (limit + 1)} />
          </div>
          <p>快啲執曬啲禮物啦！</p>
          {gifts.map(gift => <Gift key={`${gift.x}-${gift.y}`} gift={gift} onTap={handleTapGift} />)}
        </>
      )}

      {status === 'defeat' && (
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white w-full p-10 backdrop-blur flex flex-col items-center gap-5">
          <div className="text-2xl text-center">夠鐘啦！</div>
          <div className="bg-white text-blue-500 text-lg px-12 py-4 rounded-full cursor-pointer" onClick={onDefeat}>玩下一舖</div>
        </div>
      )}
    </div>
  );
}
