import React, {useEffect, useRef, useState} from 'react';
import {useColyseus, useStateRef} from './Hooks';

import AR from './Pages/AR';
import CountDown from './Pages/CountDown';
import Coupon from './Pages/Coupon';
import Map from './Pages/Map';
import Welcome from './Pages/Welcome';

function App() {
  const colyseus = useColyseus();
  const roomRef = useRef(null);
  const [gameState, setGameState] = useState({});
  const [status, statusRef, setStatus] = useStateRef('welcome');
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState(null);

  const [playerDistance, setPlayerDistance] = useState('far');
  const [playerPosition, setPlayerPosition] = useState({x: 50, y: 50});

  function onJoinGame() {
    colyseus.joinOrCreate('chaser').then(onRoomConnected);
  }

  function onMove(position) {
    setPlayerPosition(position);
    roomRef.current.send('move', position);
  }

  function onRoomConnected (room) {
    setError(null);
    setTicket([room.id, room.sessionId]);
    roomRef.current = room;

    room.onStateChange(state => {
      console.log('status updated', JSON.parse(JSON.stringify(state)));

      if (state.status === 'count-down') {
        setStatus('count-down');
      }

      if (state.status === 'playing' && statusRef.current === 'welcome') {
        setStatus('count-down');
      }

      if (state.status === 'ended') {
        if (statusRef.current !== 'coupon') {
          setStatus('welcome');
        }
        setGameState({});
        room.removeAllListeners();
        return;
      }

      setGameState(state);
    });

    room.onMessage('status', setPlayerDistance);

    room.onLeave(() => {
      setError({code: 404, title: '斷了線', description: '正在重新連接...'});
    });
  }

  function onReconnectError(error) {
    if (error.code === 4214) {
      setError({code: 408, title: '斷了線', description: '你錯過咗呢場啦，請重新整理。'});
    }
  }

  useEffect(() => {
    if (error?.code === 404) {
      colyseus
        .reconnect(...ticket)
        .then(onRoomConnected)
        .catch(onReconnectError);
    }
  }, [error]); // eslint-disable-line

  function MainComponent() {
    switch (status) {
      case 'welcome':
        return (
          <Welcome
            onJoinGame={onJoinGame}
            onTurnPage={() => setStatus('count-down')}
            startAt={gameState.startAt}
          />
        );

      case 'count-down':
        return <CountDown onTurnPage={() => setStatus('in-map')} />;

      case 'in-map':
        return (
          <Map
            position={playerPosition}
            distance={playerDistance}
            onMove={onMove}
            onTurnPage={() => setStatus('in-game')}
          />
        );

      case 'in-game':
        return <AR
          onDefeat={() => setStatus('welcome')}
          onWin={() => setStatus('coupon')}
        />;

        case 'coupon':
          return <Coupon />;

      default:
        return (
          <>
            <p>{status}</p>
            <Welcome />
          </>
        );
    }
  }

  return (
    <>
      <MainComponent />
      {error && (
        <div className="text-center absolute inset-0 flex flex-row items-center backdrop-grayscale backdrop-blur">
          <div className="bg-gradient-to-r from-pink-500 to-rose-500 text-white w-full px-5 py-10 flex flex-col gap-5">
            <div className="text-2xl font-bold">{error.title}</div>
            <div className="text-lg">{error.description}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default App;
