import React, { useEffect, useState } from 'react';

export default function Map({
  position,
  distance,
  onMove,
  onTurnPage,
}) {
  const [color, setColor] = useState('blue');
  const [arrived, setArrived] = useState(false);

  function onTap(e) {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const x = Math.round(e.clientX / width * 100);
    const y = Math.round(e.clientY / height * 100);
    onMove({x, y});
  }

  useEffect(() => {
    if (distance === 'far') {
      setColor('cyan');
    } else if (distance === 'close') {
      setColor('pink');
    } else if (distance === 'arrived') {
      setArrived(true);
    }
  }, [distance]);

  return (
    <div id="map" className="absolute inset-0 flex justify-center items-center" onClick={onTap}>
      {arrived || (
        <div className="bg-cyan-500 bg-pink-500 absolute top-0 left-0" style={{marginLeft: `${position.x / 100 * window.innerWidth}px`, marginTop: `${position.y / 100 * window.innerHeight}px`}}>
          <div className="relative">
            <div className={`bg-${color}-500 w-4 h-4 -ml-2 -mt-2 rounded-full absolute`}></div>
            <div className={`bg-${color}-500 w-4 h-4 -ml-2 -mt-2 rounded-full absolute animate-ping`}></div>
          </div>
        </div>
      )}

      {arrived && (
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white w-full p-10 backdrop-blur flex flex-col items-center gap-5">
          <div className="text-2xl text-center">你搵到聖誕老人啦！</div>
          <div className="bg-white text-blue-500 text-lg px-12 py-4 rounded-full cursor-pointer" onClick={onTurnPage}>攞禮物啦！</div>
        </div>
      )}
    </div>
  );
}
